
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/watch/[part1]/[part2]",
      function () {
        return require("private-next-pages/watch/[part1]/[part2].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/watch/[part1]/[part2]"])
      });
    }
  